<template>
  <div class="menu" v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
    <el-card class="box-card" style="width:20%;margin-right:20px;overflow:auto">
    <el-button type="primary" icon="el-icon-plus" style="margin-bottom:20px" @click="menuBox('添加菜单')">添加菜单</el-button><br>
        <el-tree :data="menuTree" node-key="id" ref="tree" highlight-current>
            <span
            slot-scope="{ data }"
            class="custom-tree-node"
            style="font-size:14px;display:block;width:100%"
            @click="share(data.id)"
            >
            <span class="menu-span" style="font-size:14px;display:block;width:100%">{{ data.name }}</span>
            </span>
        </el-tree>
    </el-card>
    <el-dialog
        title="选择父级目录"
        :visible.sync="dialogVisible"
        width="width"
        :before-close="dialogBeforeClose">
        <div>
            <el-tree default-expand-all :data="menuTree" node-key="id" ref="tree" highlight-current>
                <span
                slot-scope="{ data }"
                class="custom-tree-node"
                style="font-size:14px"
                @click="check(data.id)"
                >
                <span class="menu-span">{{ data.name }}</span>
                </span>
            </el-tree>
        </div>
        <div slot="footer">
            <el-button @click="dialogBeforeClose">取 消</el-button>
        </div>
    </el-dialog>
    <el-card v-loading="rightLoading" element-loading-text="拼命加载中" class="box-card" style="width:75%;">
        <el-form label-width="130px" style="margin-top:50px">
            <el-form-item label="父级id：">
                <span>{{itemMenuInfo.parentId}}</span>
            </el-form-item>
            <el-form-item label="菜单名(中)：">
                <span>{{itemMenuInfo.name}}</span>
            </el-form-item>
            <el-form-item label="菜单名(日)：">
                <span>{{itemMenuInfo.jpName}}</span>
            </el-form-item>
            <el-form-item label="url(路径)：">
                <span>{{itemMenuInfo.url}}</span>
            </el-form-item>
            <el-form-item label="icon图标：">
                <span>{{itemMenuInfo.icon}}</span>
            </el-form-item>
            <el-form-item label="菜单组件：">
                <span>{{itemMenuInfo.component}}</span>
            </el-form-item>
            <el-form-item label="排序：">
                <span>{{itemMenuInfo.sort}}</span>
            </el-form-item>
            <el-form-item label="菜单类型：">
                <span v-if="itemMenuInfo.type===1">目录</span>
                <span v-if="itemMenuInfo.type===2">菜单</span>
            </el-form-item>
            <el-form-item label="操作：">
                <el-button-group>
                    <el-button type="primary" icon="el-icon-edit" @click="menuBox('编辑菜单')">编辑</el-button>
                    <el-button type="danger" icon="el-icon-delete" @click="remove">删除</el-button>
                </el-button-group>
            </el-form-item>
        </el-form>
    </el-card>
    <el-dialog
    :title="title"
    width="40%"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose">
        <el-form ref="itemMenuInfo" v-loading="dialogLoading" :model="itemMenuInfo" :rules="itemMenurules" label-width="120px" style="padding:0 50px">
            <el-form-item label="父级id：" prop="parentId">
                <el-input clearable @focus="getParentId" v-model="itemMenuInfo.parentId" placeholder="父级目录"></el-input>
            </el-form-item>
            <el-form-item label="菜单名(中文)：" prop="name">
                <el-input v-model="itemMenuInfo.name" placeholder="菜单名(中文)"></el-input>
            </el-form-item>
            <el-form-item label="菜单名(日文)：" prop="jpName">
                <el-input v-model="itemMenuInfo.jpName" placeholder="菜单名(日文)"></el-input>
            </el-form-item>
            <el-form-item label="url(路径)：" prop="url">
                <el-input v-model="itemMenuInfo.url" placeholder="url(路径)"></el-input>
            </el-form-item>
            <el-form-item label="菜单组件：" prop="component">
                <el-input v-model="itemMenuInfo.component" placeholder="菜单组件"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
                <el-input v-model="itemMenuInfo.sort" placeholder="排序"></el-input>
            </el-form-item>
            <el-form-item label="icon图标：" prop="icon">
                <el-input v-model="itemMenuInfo.icon" placeholder="icon图标"></el-input>
            </el-form-item>
            <el-form-item label="菜单类型：" prop="type">
                <el-radio-group v-model="itemMenuInfo.type">
                    <el-radio label="1">目录</el-radio>
                    <el-radio label="2">菜单</el-radio>
                </el-radio-group>
            </el-form-item>
            <div style="text-align:center;margin-top:50px">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="sure()">确定</el-button>
            </div>
            
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import {menuInit, addMenu, detail, del, updateInit,edit} from '@/api/menuManagement.js'
export default {
    data(){
        return {
            dialogLoading:false,
            rightLoading:false,
            loading:false,
            shrink:false,
            dialogVisible:false,
            drawer:false,
            title:'',
            menuTree:[],
            defaultProps:{
                name:'name',
                children: 'children',
            },
            itemMenuInfo:{
                component:'',
                icon:'',
                jpName:'',
                name:'',
                parentId:'',
                sort:'',
                type:'',
                url:''
            },
            itemMenurules:{
                name:[{required:true,message:'请输入中文菜单名',trigger:'blur'}],
                jpName:[{required:true,message:'请输入日文菜单名',trigger:'blur'}],
                url:[{required:true,message:'请输入url路径',trigger:'blur'}],
                component:[{required:true,message:'请输入菜单组件',trigger:'blur'}],
                sort:[{required:true,message:'请输排列顺序',trigger:'blur'}],
                icon:[{required:true,message:'请输入icon图标',trigger:'blur'}],
                type:[{required:true,message:'请选择类型',trigger:'change'}],
            },
            id:'',
        }
    },
    mounted(){
        this.getMenuList()
    },
    methods:{
        handleClose(){
            this.drawer=false
            this.reset()
        },
        // 菜单初始化
        async getMenuList(){
            this.loading=true
            const {data} = await menuInit()
            console.log(data.data);
            if(data.code==200){
                this.menuTree=data.data
            }
            this.loading=false
        },
        menuBox(data){
            this.title=data
            if(data=='添加菜单'){
                this.itemMenuInfo={
                    component:'',
                    icon:'',
                    jpName:'',
                    name:'',
                    parentId:'',
                    sort:'',
                    type:'',
                    url:''
                }
                this.drawer=true
            }
            // 编辑初始化
            if(data=='编辑菜单'){
                if(!this.id){
                    this.$message({
                        type:'warning',
                        message:'请选择具体菜单或目录'
                    })
                    return 
                }else {
                    this.dialogLoading=true
                    this.drawer=true
                    updateInit(this.id).then(res=>{
                        if(res.data.code==200){
                            this.itemMenuInfo=res.data.data
                            this.itemMenuInfo.type=String(this.itemMenuInfo.type)
                        }
                        this.dialogLoading=false
                    })
                    
                }
                
                return
            }
        },
        reset(){
            this.$refs.itemMenuInfo.resetFields()
            this.$refs.itemMenuInfo.clearValidate()
        },
        sure(){
            this.$refs.itemMenuInfo.validate(valid=>{
                if(valid){
                    if(this.title=='添加菜单'){
                        this.dialogLoading=true
                        addMenu(this.itemMenuInfo).then(res=>{
                            console.log(res);
                            if(res.data.code==200){
                                this.$message({
                                    type:'success',
                                    message:res.data.message
                                })
                                this.dialogLoading=false
                                this.drawer=false
                                this.reset()
                                this.getMenuList()
                            }else {
                                this.$message({
                                    type:'warning',
                                    message:res.data.message
                                })
                                this.dialogLoading=false
                            }
                        })
                    }
                    if(this.title=='编辑菜单'){
                        this.dialogLoading=true
                        edit({...this.itemMenuInfo,id:this.id}).then(res=>{
                            console.log(res);
                            if(res.data.code==200){
                                this.$message({
                                    type:'success',
                                    message:res.data.message
                                })
                                this.dialogLoading=false
                                this.drawer=false
                                // this.reset()
                                tgis.menuBox(this.title)
                                this.getMenuList()
                            }else{
                                this.$message({
                                    type:'warning',
                                    message:res.data.message
                                })
                                this.dialogLoading=false
                            }
                        })
                    }
                }
            })
        },
        //删除
        remove(){
            if(!this.id){
                this.$message({
                    type:'warning',
                    message:'请选择具体菜单或目录'
                })
                return 
            }
            this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                del(this.id).then(res=>{
                    console.log(res);
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.itemMenuInfo={
                            component:'',
                            icon:'',
                            jpName:'',
                            name:'',
                            parentId:'',
                            sort:'',
                            type:'',
                            url:''
                        }
                        this.getMenuList()
                        this.id=''
                    }else{
                        this.$message({
                            type:'warning',
                            message:res.data.message
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        // 查看详情
        share(id){
            this.id=id
            this.rightLoading=true
            detail(id).then(res=>{
                console.log(res);
                if(res.data.code==200){
                    this.itemMenuInfo=res.data.data
                    this.rightLoading=false
                }
            })
        },
        getParentId(){
            this.dialogVisible=true
        },
        check(id){
            this.itemMenuInfo.parentId=id
            console.log(id);
            console.log(this.itemMenuInfo.parentId);
            this.dialogVisible=false
        },
        dialogBeforeClose(){
            this.itemMenuInfo.parentId=''
            this.dialogVisible=false
        }
    }
}
</script>

<style lang='scss' scoped>
.menu{
    width: 100%;
    // height: 100%;
}
.box-card{
    display: inline-block;
    height: 800px;
}

</style>